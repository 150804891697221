window.common = window.common || {};

const EDUCATION_HOST = "https://education.tealium.com";
const SUPPORT_HOST = "https://support.tealiumiq.com";
const STATUS_HOST = "https://status.tealium.com";
const DEV_DOCS_HOST = "https://docs.tealium.com";
const TEALIUM_HOST = "https://tealium.com";
const UNIVERSITY_HOST = "https://university.tealium.com";
const PRODUCT_IDEAS_HOST = "https://productideas.tealium.com";

common.community = {
    about_connectors: DEV_DOCS_HOST + "/server-side/connectors/about/",
    about_data_mappings: DEV_DOCS_HOST + "/iq-tag-management/data-mappings/about/",
    adding_a_tag: DEV_DOCS_HOST + "/iq-tag-management/tags/tag-marketplace/add-a-tag/",
    adding_data_sources:
        DEV_DOCS_HOST + "/server-side/getting-started/eventstream-api-hub/data-sources-add-a-data-source/",
    adobe_analytics_tag: DEV_DOCS_HOST + "/client-side-tags/adobe-analytics-sitecatalyst-tag-legacy/",
    audienceDB_eventDB: DEV_DOCS_HOST + "/server-side/data-storage/audiencedb-and-eventdb/",
    audienceDB_eventDB_about: DEV_DOCS_HOST + "/server-side/data-storage/audiencedb-eventdb/about/",
    audienceStore_eventStore: DEV_DOCS_HOST + "/server-side/data-storage/audiencestore-and-eventstore/",
    audiencedirect_connector: DEV_DOCS_HOST + "/server-side/data-storage/audiencestore-eventstore/",
    audience_sizing: DEV_DOCS_HOST + "/server-side/audiences/audience-sizing/about/",
    audience_stream_dashboard: DEV_DOCS_HOST + "/server-side/audiences/audiencestream-dashboard/",
    audience_stream_live_events:
        DEV_DOCS_HOST + "/server-side/getting-started/audiencestream-cdp/audiencestream-install-options-for-web/",
    badge_associations: DEV_DOCS_HOST + "/server-side/attributes/data-types/badge/#badge_associations",
    ccpa_display_rule_section:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/privacy-banner-and-popup/about/#toc-hId-124690707",
    ccpa_template_selection: DEV_DOCS_HOST + "/iq-tag-management/consent-management/privacy-banner-and-popup/about/",
    channels_extension: DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/channels-extension/",
    china_cdn_extension:
        DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/china-cdn-deployment-extension/",
    community_page: DEV_DOCS_HOST + "/",
    consent_management_global_consent_parameters:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/global-parameters/",
    consent_preferences_manager:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/consent-preferences-dialog/about/",
    consent_global_resources: DEV_DOCS_HOST + "/iq-tag-management/consent-management/global-parameters/",
    consent_preferences_manager_consent:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/explicit-consent-prompt/about/",
    consent_prompt_manager: DEV_DOCS_HOST + "/iq-tag-management/consent-management/explicit-consent-prompt/about/",
    create_custom_tools: DEV_DOCS_HOST + "/iq-tag-management/tealium-tools/browser-extension/",
    custom_parameters:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/consent-preferences-dialog/about/#toc-hId-585202497",
    data_access_console_eventstore: DEV_DOCS_HOST + "/server-side/data-storage/audiencestore-and-eventstore/",
    data_access_eventdb: DEV_DOCS_HOST + "/server-side/data-storage/eventdb-data-guide/",
    data_layer_enrichment: DEV_DOCS_HOST + "/server-side/attributes/data-layer-enrichment/about/",
    dle_extension: DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/hosted-data-layer-extension/",
    dle_api_overview: DEV_DOCS_HOST + "/api/hosted-data-layer/about/",
    data_layer_tab: DEV_DOCS_HOST + "/iq-tag-management/data-layer/about/",
    data_source_types: DEV_DOCS_HOST + "/server-side/data-sources/file-import/prepare-a-csv-file/",
    data_sources_for_iq: DEV_DOCS_HOST + "/server-side/data-sources/data-sources-for-iq-tag-management/",
    do_not_sell_prompt_setup_guide:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/privacy-banner-and-popup/about/",
    documents_page: DEV_DOCS_HOST,
    early_access: DEV_DOCS_HOST + "/early-access/",
    ecommerce_extension: DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/e-commerce-extension/",
    enabling_experiments: DEV_DOCS_HOST + "/server-side/administration/enabling-experiments/",
    explicit_consent: DEV_DOCS_HOST + "/iq-tag-management/consent-management/explicit-consent-prompt/about/",
    explicit_consent_consent_rules:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/explicit-consent-prompt/about/",
    explicit_consent_custom_parameters:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/explicit-consent-prompt/about/",
    explicit_consent_customizations:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/explicit-consent-prompt/about/",
    explicit_consent_event_logging:
        DEV_DOCS_HOST + "/iq-tag-management/consent-management/explicit-consent-prompt/about/#toc-hId--960219467",
    extension_series: DEV_DOCS_HOST + "/iq-tag-management/extensions/about/",
    extensions_full_list: DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/",
    extensions_getting_started: DEV_DOCS_HOST + "/iq-tag-management/getting-started/extension-basics/",
    extensions_order_of_operations: DEV_DOCS_HOST + "/iq-tag-management/getting-started/order-of-operations/",
    extensions_tab: DEV_DOCS_HOST + "/iq-tag-management/extensions/about/",
    event_connectors: DEV_DOCS_HOST + "/server-side/connectors/about/",
    event_spec: DEV_DOCS_HOST + "/server-side/event-specifications/about/",
    feed_announcements: DEV_DOCS_HOST + "/zjzhh79394/plugins/custom/tealium/tealium/feed_announcements",
    file_import_csv: DEV_DOCS_HOST + "/server-side/data-sources/file-import/prepare-a-csv-file/",
    feedback_load_order_manager: DEV_DOCS_HOST + "/iq-tag-management/extensions/load-order-manager/",
    github_syncing_resource: DEV_DOCS_HOST + "/iq-tag-management/administration/linking-to-github/about/",
    guide_tiq_users: DEV_DOCS_HOST + "/server-side/data-sources/data-sources-for-iq-tag-management/",
    help_support_documentation: DEV_DOCS_HOST,
    help_support_education_training: EDUCATION_HOST,
    help_support_education_training_catalog: EDUCATION_HOST + "/catalog",
    help_support_knowledge_base: SUPPORT_HOST + "/en/support/solutions",
    help_support_support_desk: SUPPORT_HOST + "/en/support/tickets",
    help_support_production_status: STATUS_HOST,
    how_to_fix_magento:
        DEV_DOCS_HOST +
        "t5/Customer-Documentation/How-to-fix-magento-quot-404-Error-Page-not-found-quot-with/ta-p/5921",
    how_to_use_tealiumiq_custom_tags: DEV_DOCS_HOST + "/iq-tag-management/tags/tealium-custom-container-tag/",
    important_multi_factor_authentication_for_tealium:
        DEV_DOCS_HOST + "/iq-tag-management/administration/security/mfa/",
    integrating_magento_and_tealiumiq: DEV_DOCS_HOST + "/partners/cms-plugins/",
    installation_affirm: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/affirm/",
    installation_android: DEV_DOCS_HOST + "/platforms/android-kotlin/install/",
    installation_cordova: DEV_DOCS_HOST + "/platforms/cordova/install/",
    installation_c_sharp: DEV_DOCS_HOST + "/platforms/csharp/install/",
    installation_file_import: DEV_DOCS_HOST + "/server-side/data-sources/file-import/about-file-import/",
    installation_google_amp: DEV_DOCS_HOST + "/platforms/amp/install/",
    installation_google_tag_manager: "https://docs.tealium.com/platforms/google-tag-manager/",
    installation_adobe_launch: "https://docs.tealium.com/platforms/adobe-launch/",
    installation_http_api_flattened: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/http-api-advanced/",
    installation_hubspot_wf: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/hubspot/",
    installation_intercom: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/intercom/",
    installation_ios: DEV_DOCS_HOST + "/platforms/ios-swift/",
    installation_iterable: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/iterable/",
    installation_java: DEV_DOCS_HOST + "/platforms/java/install/",
    installation_mailchimp: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/mailchimp/",
    installation_omnichannel: DEV_DOCS_HOST + "/server-side/data-sources/importing-offline-data-omnichannel/",
    installation_python: DEV_DOCS_HOST + "/platforms/python/install/",
    installation_rest: DEV_DOCS_HOST + "/platforms/http-api/endpoint/",
    installation_roku: DEV_DOCS_HOST + "/platforms/roku/install/",
    installation_salesforce: DEV_DOCS_HOST + "/server-side-connectors/tealium-for-salesforce/",
    installation_sendgrid: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/sendgrid/",
    installation_slack: "https://api.slack.com/custom-integrations/outgoing-webhooks", // TODO: update to TLC link when installation article is created
    installation_swift: DEV_DOCS_HOST + "/platforms/ios-swift/install/",
    installation_tiqJavascript: DEV_DOCS_HOST + "/platforms/javascript/install/",
    installation_tvOS: DEV_DOCS_HOST + "/platforms/ios-objective-c/tvos/",
    installation_watchOS: DEV_DOCS_HOST + "/platforms/ios-objective-c/watchos/",
    installation_zapier: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/zapier/",
    installation_airship: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/airship/",
    installation_auth0: DEV_DOCS_HOST + "/industries/tealium-identity-partners/about/",
    installation_google_ads_leads: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/google-ads-leads/",
    installation_insider: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/insider/",
    installation_pulsepoint_hcp_identity: DEV_DOCS_HOST + "/server-side/data-sources/webhooks/pulsepoint_hcp_identity/",
    intro_data_sources: DEV_DOCS_HOST + "/server-side/data-sources/about-data-sources/",
    intro_eventstream: DEV_DOCS_HOST + "/server-side/getting-started/eventstream-api-hub/introduction/",
    intro_audiencestream: DEV_DOCS_HOST + "/server-side/getting-started/audiencestream-cdp/introduction/",
    jquery_onhandler_extension:
        DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/jquery-onhandler-extension/",
    jscode_extension:
        DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/advanced-javascript-code-extension/",
    load_order_manager: DEV_DOCS_HOST + "/iq-tag-management/extensions/load-order-manager/",
    load_rules_creation: DEV_DOCS_HOST + "/iq-tag-management/load-rules/manage-load-rules/",
    managing_profiles: DEV_DOCS_HOST + "/iq-tag-management/profiles/manage/",
    merge_into_current_session_versions_tab: DEV_DOCS_HOST + "/iq-tag-management/save-publish/merging-versions/",
    merging_changes_between_concurrent_users:
        DEV_DOCS_HOST + "/iq-tag-management/save-publish/merging-changes-between-concurrent-users/",
    mobile_app_libraries_series: DEV_DOCS_HOST + "/platforms/getting-started-mobile/",
    multi_factor_authentication_for_tealium: DEV_DOCS_HOST + "/iq-tag-management/administration/security/mfa/",
    offline_data_omnichannel: DEV_DOCS_HOST + "/server-side/data-sources/file-import/about/",
    omnichannel_enrichment: DEV_DOCS_HOST + "/server-side/data-sources/importing-offline-data-omnichannel/",
    optimizely: DEV_DOCS_HOST + "/tech-partners/",
    oracle_commerce_integration_guide: DEV_DOCS_HOST + "/client-side-tags/oracle-cx-tag/",
    platforms_android_module_ad_identifier: DEV_DOCS_HOST + "/platforms/android-kotlin/module-list/adid",
    platforms_android_module_crash_reporter: DEV_DOCS_HOST + "/platforms/android-kotlin/module-list/crash-reporter",
    platforms_android_module_in_app_purchase: DEV_DOCS_HOST + "/platforms/android-kotlin/module-list/in-app-purchase",
    platforms_android_module_install_referrer: DEV_DOCS_HOST + "/platforms/android-kotlin/module-list/install-referrer",
    platforms_android_module_location: DEV_DOCS_HOST + "/platforms/android-kotlin/module-list/location",
    platforms_ios_module_attribution: DEV_DOCS_HOST + "/platforms/ios-swift/module-list/attribution",
    platforms_ios_module_crash_reporter: DEV_DOCS_HOST + "/platforms/ios-swift/module-list/crash-reporter",
    platforms_ios_module_in_app_purchase: DEV_DOCS_HOST + "/platforms/ios-swift/module-list/in-app-purchase",
    platforms_ios_module_location: DEV_DOCS_HOST + "/platforms/ios-swift/module-list/location",
    predict_learn_more: DEV_DOCS_HOST + "/predict/advanced/machine-learning-vs-artificial-intelligence/",
    predict_more_info: DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/",
    predict_more_info_confusion_matrix:
        DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId--268906382",
    predict_more_info_accuracy: DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId--268906382",
    predict_more_info_precision: DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId-476046190",
    predict_more_info_recall: DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId-476046190",
    predict_more_info_f1: DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId-476046190",
    predict_more_info_roc: DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId--1901362635",
    predict_more_info_auc: DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId--1901362635",
    predict_more_info_prob_dist:
        DEV_DOCS_HOST + "/predict/evaluate-models/probability-distribution/#toc-hId--126355273",
    predict_free_tier: DEV_DOCS_HOST + "/predict/getting-started",
    predict_attribute: DEV_DOCS_HOST + "/predict/getting-started/terminology/#prediction-value",
    preloaded_attribute: DEV_DOCS_HOST + "/t5/TLC-Blog/Preloaded-Attributes/ba-p/37103",
    product_ideas: PRODUCT_IDEAS_HOST + "/",
    product_ideas_most_recent: PRODUCT_IDEAS_HOST + "/",
    publish_configurations: DEV_DOCS_HOST + "/iq-tag-management/save-publish/publish-configuration",
    publish_configurations_implementations:
        DEV_DOCS_HOST + "/iq-tag-management/save-publish/publish-configuration#implementation",
    restricted_data: DEV_DOCS_HOST + "/server-side/attributes/restricted-data/",
    restricted_data_attribute: DEV_DOCS_HOST + "/server-side/attributes/restricted-data/",
    save_publish_a_version: DEV_DOCS_HOST + "/iq-tag-management/save-publish/about-saving/",
    //    scan_companion: DEV_DOCS_HOST + "/t5/Pulled-Content/Scan-Companion/m-p/2813",
    server_to_server_config: DEV_DOCS_HOST + "/t5/Customer-Documentation/Server-to-Server-Configuration/ta-p/294",
    //    site_scan: DEV_DOCS_HOST + "/t5/Customer-Documentation/Site-Scan/ta-p/5065",
    tag_configuration_advanced_settings: DEV_DOCS_HOST + "/iq-tag-management/tags/tag-templates/manage/",
    tag_marketplace: DEV_DOCS_HOST + "/iq-tag-management/tags/tag-marketplace/about/",
    tag_reports_in_tealiumiq: DEV_DOCS_HOST + "/iq-tag-management/tags/tag-reporting/#M329",
    tags_getting_started: DEV_DOCS_HOST + "/iq-tag-management/getting-started/tag-basics/",
    tags_tab: DEV_DOCS_HOST + "/iq-tag-management/tags/tag-templates/manage/",
    tealium_learning_community: DEV_DOCS_HOST + "/",
    tealium_tools_chrome_extension_custom_tools: DEV_DOCS_HOST + "/iq-tag-management/tealium-tools/build/",
    tealium_tools_utag_debugger_install:
        DEV_DOCS_HOST + "/iq-tag-management/troubleshooting/utag-debugger/#toc-hId--270693806",
    tealium_tools_utag_debugger_using:
        DEV_DOCS_HOST + "/iq-tag-management/troubleshooting/utag-debugger/#toc-hId-441811665",
    time_based_load_rule: DEV_DOCS_HOST + "/iq-tag-management/load-rules/examples/",
    tracking_video_interactions_with_tealiumiq:
        DEV_DOCS_HOST + "/platforms/getting-started-web/data-layer/definitions/basic-video-tracking/",
    transitioning_to_google_universal_analytics:
        DEV_DOCS_HOST + "/client-side-tags/google-analytics-ga-to-google-universal-analytics-gua/",
    understanding_visitor_stitching:
        DEV_DOCS_HOST + "/server-side/getting-started/audiencestream-cdp/visitor-stitching/",
    universal_data_object_guide: DEV_DOCS_HOST + "/platforms/javascript/universal-data-object/",
    upcoming_load_order_manager: DEV_DOCS_HOST + "/iq-tag-management/extensions/load-order-manager/",
    using_extensions: DEV_DOCS_HOST + "/iq-tag-management/extensions/about/",
    using_tags: DEV_DOCS_HOST + "/iq-tag-management/tags/tag-templates/manage/",
    utag_js_order_of_operation: DEV_DOCS_HOST + "/iq-tag-management/getting-started/order-of-operations/",
    utag_js_release_notes_series: DEV_DOCS_HOST + "/platforms/javascript/release-notes/",
    utag_js_versions_433_439_release_notes: DEV_DOCS_HOST + "/platforms/javascript/release-notes/",
    utag_sync_requirement:
        DEV_DOCS_HOST + "/client-side-tags/adobe-target-using-global-mbox-with-utagsyncjs/#introducing_utag_sync_js",
    view_through_extension:
        DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/view-through-tracking-extension/",
    verify_data_layer_data_layer_specs: DEV_DOCS_HOST + "/iq-tag-management/data-layer/about/",
    verify_data_layer_overview: DEV_DOCS_HOST + "/iq-tag-management/data-layer/about/",
    verify_data_layer_test_diagnostic_guide: DEV_DOCS_HOST + "/iq-tag-management/data-layer/about/",
    verify_data_layer_test_recorder: DEV_DOCS_HOST + "/iq-tag-management/data-layer/about/",
    versions_tab: DEV_DOCS_HOST + "/iq-tag-management/save-publish/version-history/",
    visitor_deletion_jobs: DEV_DOCS_HOST + "/early-access/visitor-deletion-jobs/",
    web_companion: DEV_DOCS_HOST + "/iq-tag-management/troubleshooting/web-companion/",
    webhook_audiencestream_connector: DEV_DOCS_HOST + "/server-side/connectors/webhook-connectors/about/",
    working_with_audiencestore: DEV_DOCS_HOST + "/server-side/data-storage/audiencestore-and-eventstore/",
    working_with_audiencedb_and_eventdb: DEV_DOCS_HOST + "/server-side/data-storage/audiencedb-and-eventdb/",
    labels_character_error: DEV_DOCS_HOST + "/iq-tag-management/labels/about/#label_naming_requirements",
    api_v2_getting_started: DEV_DOCS_HOST + "/iq-tag-management/administration/security/api-keys/",
    preparing_gdpr: DEV_DOCS_HOST + "/t5/Announcements/Preparing-for-GDPR/m-p/22574",
    preparing_ccpa: DEV_DOCS_HOST + "/t5/Announcements/<TO DO>",
    university_page: UNIVERSITY_HOST,
    visitor_lookup:
        DEV_DOCS_HOST + "/early-access/api-v3/tealium-api-v3-visitor-privacy-api-formerly-visitor-lookup-api/",
    youtube: DEV_DOCS_HOST + "/iq-tag-management/events/event-types/youtube-event/",
    tags: {
        act_on: DEV_DOCS_HOST + "/client-side-tags/acton-tag/",
        adform: DEV_DOCS_HOST + "/client-side-tags/adform-tag/",
        adobe_heartbeat_tag: DEV_DOCS_HOST + "/client-side-tags/adobe-heartbeat-tag/",
        adobe_test_and_target_series: DEV_DOCS_HOST + "/client-side-tags/adobe-test-target-tag/",
        ad_marketplace_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/admarketplace-tag/",
        ad_roll_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/adroll-smart-pixel-tag/",
        advertising_com_dynamic_retargeter_tag:
            DEV_DOCS_HOST + "/client-side-tags/advertisingcom-dynamic-retargeter-tag/",
        affiliate_future: DEV_DOCS_HOST + "/t5/Customer-Documentation/AffiliateFuture/ta-p/5864",
        app_measurement_for_javascript_tag: DEV_DOCS_HOST + "/client-side-tags/adobe-appmeasurement-for-js-tag/",
        app_nexus_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/xandr-formerly-appnexus-tag/",
        audience_stream_capability_learn_more:
            DEV_DOCS_HOST + "/iq-tag-management/data-mappings/about/#audiencestream_capable",
        channel_advisor_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/channeladvisor-tag/",
        channel_intelligence_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/channel-intelligence-tag/",
        chartbeat_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/chartbeat-tag/",
        click_tale_balkan_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/clicktale-carmel-tag/",
        commission_factory: DEV_DOCS_HOST + "/client-side-tags/commission-factory-au-tag/",
        commission_junction_tag_basic_config:
            DEV_DOCS_HOST + "/client-side-tags/cj-affiliate-formerly-commission-junction-tag/",
        com_score_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/comscore-tag/",
        convertro_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/convertro-tag-basic-configuration/",
        crazy_egg_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/crazy-egg-tag/",
        criteo_one_tag: DEV_DOCS_HOST + "/client-side-tags/criteo-onetag-tag/",
        criteo_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/criteo-onetag-tag/",
        deploying_the_soasta_rum_tag_through_tealium: DEV_DOCS_HOST + "/client-side-tags/akamai-mpulse-tag/",
        double_click_floodlight_basic_config:
            DEV_DOCS_HOST + "/server-side-connectors/doubleclick-ad-exchange-connector/",
        double_click_spotlight_tag_deprecated_basic_config:
            DEV_DOCS_HOST + "/client-side-tags/google-ad-manager-spotlight-tag/",
        facebook_conversions_basic_config: DEV_DOCS_HOST + "/server-side-connectors/facebook-conversions-connector/",
        facebook_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/facebook-pixel-legacy/",
        facebook_tag_6037_basic_config: DEV_DOCS_HOST + "/iq-tag-management/getting-started/order-of-operations/83",
        google_ad_words_conversion_tag_config:
            DEV_DOCS_HOST + "/server-side-connectors/google-ads-conversions-connector/",
        google_ad_words_remarketing_tag: DEV_DOCS_HOST + "/client-side-tags/google-ads-remarketing-tag/",
        google_analytics_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/google-analytics-gtagjs-tag/",
        google_universal_analytics_series: DEV_DOCS_HOST + "/client-side-tags/google-analytics-series/",
        heap_tag: DEV_DOCS_HOST + "/client-side-tags/heap-tag/",
        ibm_coremetrics_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/acoustic-digital-analytics-tag/",
        kenshoo_conversion_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/kenshoo-conversion-tag/",
        link_share_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/linkshare-tag/",
        marin_software_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/marin-software-tag/",
        media_math_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/mediamath-tag-basic-configuration/",
        media_mind_bursting_pipe_sizmek_mdx_tag_basic_config:
            DEV_DOCS_HOST + "/client-side-tags/mediamind-burstingpipe-tag/",
        microsoft_ad_center_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/bing-ads-microsoft-adcenter-tag/",
        nextag_roi_tracker_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/nextag-roi-tracker-tag/",
        optimizely_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/optimizely-sync-tag/",
        price_grabber_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/pricegrabber-tag/",
        quantcast_advertise_custom_tag_basic_config:
            DEV_DOCS_HOST + "/client-side-tags/quantcast-easy-tag-for-advertise/",
        right_media_yieldmanager_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/right-media-yieldmanager-tag/",
        rocket_fuel_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/rocket-fuel-tag/",
        shopzilla_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/shopzilla-tag/",
        site_catalyst_series: DEV_DOCS_HOST + "/client-side-tags/sitecatalyst-tag/",
        splunk_tag: DEV_DOCS_HOST + "/client-side-tags/splunk-tag/",
        tealiumiq_custom_container_series: DEV_DOCS_HOST + "/iq-tag-management/tags/tealium-custom-container-tag/",
        tealium_collect_tag: DEV_DOCS_HOST + "/client-side-tags/tealium-collect-tag/",
        tealium_currency_converter_tag: DEV_DOCS_HOST + "/client-side-tags/tealium-currency-converter-tag/",
        tealium_eventstore_legacy_tag:
            DEV_DOCS_HOST + "/t5/Employee-Documentation/Tealium-EventStore-Legacy-Tag/ta-p/710",
        tealium_generic_tag: DEV_DOCS_HOST + "/iq-tag-management/tags/tealium-generic-tag/",
        tealium_pixel_container_tag: DEV_DOCS_HOST + "/iq-tag-management/tags/tealium-pixel-container-tag/",
        tealium_seo_json_ld_basic_config: DEV_DOCS_HOST + "/client-side-tags/tealium-seo-json-ld-tag/",
        trade_doubler_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/tradedoubler-conversion-tracking-tag/",
        tribal_fusion_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/tribal-fusion-tag/",
        twitter_universal_website: DEV_DOCS_HOST + "/client-side-tags/twitter-universal-website-tag/",
        tiktok_pixel_website: DEV_DOCS_HOST + "/client-side-tags/tiktok-pixel-tag/#breadcrumb",
        usercentrics_setup_guide:
            DEV_DOCS_HOST + "/iq-tag-management/extensions/extensions-list/usercentrics-extension/",
        webtrends_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/webtrends-tag/",
        x_plus_one_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/x-plus-one-tag/",
        zanox_tag_basic_config: DEV_DOCS_HOST + "/client-side-tags/zanox-tag/",
    },
    products: {
        data_access: TEALIUM_HOST + "/products/tealium-dataaccess/#1425073875304-2-56c15-3f10",
    },
    tools: {
        chrome_extension: "https://chrome.google.com/webstore/detail/tealium-tools/gidnphnamcemailggkemcgclnjeeokaa",
        firefox_extension: "https://addons.mozilla.org/en-US/firefox/addon/tealiumtools/",
        usercentrics: "https://tealium-tools.s3.amazonaws.com/tools/usercentrics.js",
    },
};
